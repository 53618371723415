/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import ReactGA from 'react-ga'

const domain = 'localhost'
let id = ''

if (document.location.hostname.indexOf(domain) !== -1) {
  id = 'UA-3281218-16'
}
// console.log(id);

ReactGA.initialize(id, {
  debug: false,
});

export const onRouteUpdate = async (state) => {
  ReactGA.set({ page: state.location.pathname })
  ReactGA.pageview(state.location.pathname)
  
}

export const onServiceWorkerUpdateReady = () => {
  console.log('%c --- onServiceWorkerUpdateReady: Code has been updated.', 'color: blue');
}

import 'bootstrap/scss/bootstrap.scss';
import './src/css/fonts.scss';
import './src/css/variables.scss';
import './src/css/main-content.scss';
import './src/css/responsive.scss';